
import React, { useEffect, useState } from "react";
import screen from "../../../assets/images/creator_guide/caret-down-fill.svg";
import { queryAllTagCategoryOfCreatorBaseForMerchant } from "../../../services/blog";

interface NavPhoneProps {
  navActiveId: string;
  navName: string;
  handleNavActive: (id: string) => void;
}

const NavPhoneFc: React.FC<NavPhoneProps> = (props) => {
  const { navActiveId, navName, handleNavActive } = props;

  const [coreatorTags, setCoreatorTags] = useState([]);
  useEffect(() => {
    handleCreatorCartgory();
  }, []);
  const handleCreatorCartgory = async () => {
    const req = {};
    const res = await queryAllTagCategoryOfCreatorBaseForMerchant(req);
    if (res?.header?.success) {
      setCoreatorTags(res.tags);
    }
  };

  const onActive = {
    color: "#fff",
    backgroundColor: "#f5000a",
    borderRadius: "9px",
  };

  const offActive = {
    color: "#000",
    backgroundColor: "#fff",
    borderRadius: "9px",
  };

  return (
    <div className="nav">
      <nav className="navbar w-100 bg-f b-m-1">
        <div className="container">
          <a
            className="navbar-brand w-100"
            aria-expanded="false"
            aria-controls="collapseExample"
            data-bs-toggle="collapse"
            href="#collapseExample"
          >
            <span style={{ fontSize: "14px" }}>{navName}</span>
            &nbsp;
            <span>
              <img src={screen} alt="" />
            </span>
          </a>
        </div>
        <div className="collapse w-100" id="collapseExample">
          <div className="card card-body initialCard">
            <ul className="nav flex-column">
              <li className="nav-item" key={0}>
                <a
                  className="nav-link nav-color-0 pointer"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                  data-bs-toggle="collapse"
                  href="#collapseExample"
                  style={navActiveId == "0" ? onActive : offActive}
                  onClick={() => handleNavActive(0,"全部")}
                >
                  全部
                </a>
              </li>
              {coreatorTags?.map((item) => [
                <li className="nav-item" key={item.id}>
                  <a
                    className="nav-link nav-color-0 pointer"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                    data-bs-toggle="collapse"
                    href="#collapseExample"
                    style={navActiveId == item.id ? onActive : offActive}
                    onClick={() => handleNavActive(item.id, item.name)}
                  >
                    {item.name}
                  </a>
                </li>,
              ])}
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default NavPhoneFc;
